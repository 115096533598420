<template>
  <div class="hall-personal">
    <div class="container">
      <main class="aside-box">
        <router-view></router-view>
      </main>
      <aside class="blog_container_aside">
        <div id="asideProfile" class="aside-box">
          <div class="profile-avter"><img src="~@/assets/images/hall/hall-index-banner.png" /></div>
          <div class="profile-name">很长的微信名</div>
          <p class="profile-tel">（18030121426）</p>
        </div>
        <div id="asideRouter" class="aside-box">
          <div class="aside-box-title mar-B40"><router-link to="/hall/personal/hall-related">我相关的馆</router-link></div>
          <div class="aside-box-msg">
            <div class="aside-box-title mar-B24">消息管理</div>
            <ul class="inf_list clearfix">
              <li class="clearfix">
                <router-link to="/hall/personal/hall-interact">互动消息</router-link>
              </li>
              <li class="clearfix">
                <router-link to>系统消息</router-link>
              </li>
              <li class="clearfix">
                <router-link to>消息设置</router-link>
              </li>
            </ul>
          </div>
          <div class="aside-box-title mar-B32"><router-link to>服务条款</router-link></div>
          <div class="aside-box-title mar-B46"><router-link to>关于我们</router-link></div>
          <div class="aside-box-title"><router-link to>在线客服</router-link></div>
        </div>
      </aside>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'hall-personal',
  }
</script>

<style lang="scss" scoped>
.hall-personal {
  width: 100%;
  position: relative;
  z-index: 2;
  margin-top: 82px;
  .container {
    background: url("~@/assets/images/hall/create/hall-create-bg.png") no-repeat center;
    background-size: 100% 100%;
    .aside-box {
      background: #FFFFFF;
      border-radius: 10px;
    }
    main {
      float: right;
      display: block;
      width: 73%;
      height: 874px;
      margin-top: 26px;
      padding: 20px 22px;
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
      overflow-x: hidden;
      overflow-y: auto;

      &::-webkit-scrollbar {
         display: none; /* Chrome Safari */
       }
    }
    aside {
      width: 25%;
      float: left;
      display: block;
      #asideRouter {
        height: 630px;
        padding: 42px 32px;
        .aside-box-title {
          font-size: 20px;
          font-weight: bold;
          color: #444444;
          line-height: 26px;
          a:hover {
            color: #427EB3;
          }
        }
        .aside-box-msg {
          ul {
            margin: 0;
            padding: 0;
            li {
              font-size: 16px;
              font-weight: 400;
              color: #444444;
              margin-bottom: 24px;
              a:hover {
                color: #427EB3;
              }
            }
          }
        }
      }
      #asideProfile {
        margin: 26px 0 20px 0;
        padding: 20px;
        .profile-avter {
          text-align: center;
          img {
            width: 120px;
            height: 120px;
            border-radius: 50%;
            overflow: hidden;
          }
        }
        .profile-name {
          text-align: center;
          font-size: 20px;
          font-weight: bold;
          color: #6C6C6C;
          margin-top: 12px;
          line-height: 26px;
        }
        .profile-tel {
          text-align: center;
          font-size: 16px;
          font-weight: 400;
          color: #6C6C6C;
          line-height: 26px;
          margin: 0;
        }
      }
    }
    &::after{
     content: "";
     display: block;
     clear: both;
     height: 0;
     visibility: hidden;
     }
  }
}
</style>
